import '../css/app.css'

import './menu.js'
import './wooAttributes.js'
import './quantity.js'
import './product.js'
import './extraFieldsPrices.js'
import './mail.js'
import './shareCart.js'

/**
 * 🦄: Now do your magic.
 */

import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'

window.Alpine = Alpine
Alpine.plugin(collapse)
Alpine.start()

/**
 * 🌈: Swiper
 */

import Swiper from 'swiper/bundle'
import 'swiper/css/bundle'
import 'swiper/css'

var swiper = new Swiper('.mySwiper', {
  spaceBetween: 10,
  slidesPerView: 4,
  freeMode: true,
  watchSlidesProgress: true
})
var swiper2 = new Swiper('.mySwiper2', {
  spaceBetween: 10,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  thumbs: {
    swiper: swiper
  }
})

var swiperOverflow = new Swiper('.swiper-coverflow', {
  effect: 'coverflow',
  grabCursor: true,
  initialSlide: 1,
  centeredSlides: true,
  slidesPerView: 'auto',
  loop: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  coverflowEffect: {
    rotate: 10,
    stretch: 0,
    depth: 200,
    modifier: 1,
    slideShadows: true
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
      centeredSlides: true,
      initialSlide: 1,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      }
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 30,
      centeredSlides: true,
      autoplay: false,
      initialSlide: 2,
      loop: true
    }
  }
})
