/* global textCopied */

document.addEventListener('DOMContentLoaded', function () {
  var shareCartButton = document.getElementById('share-cart-button')
  if (!shareCartButton) {
    return
  }

  shareCartButton.addEventListener('click', function (event) {
    event.preventDefault()
    var shareableCartUrl = shareCartButton.getAttribute('data-url')

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(shareableCartUrl)
        .then(function () {
          var originalText = shareCartButton.innerText
          shareCartButton.innerText = textCopied
          setTimeout(function () {
            shareCartButton.innerText = originalText
          }, 2000)
        })
        .catch(function (err) {
          console.error('Could not copy text: ', err)
        })
    } else {
      // Fallback method for older browsers
      var textArea = document.createElement('textarea')
      textArea.value = shareableCartUrl
      document.body.appendChild(textArea)
      textArea.select()
      try {
        // eslint-disable-next-line
        document.execCommand('copy')
        var originalText = shareCartButton.innerText
        shareCartButton.innerText = textCopied
        setTimeout(function () {
          shareCartButton.innerText = originalText
        }, 2000)
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }
      document.body.removeChild(textArea)
    }
  })
})
