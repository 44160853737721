/* global initialPrice, utforandeBreddValues, heightPrice, breddPrice, variationPrices updatePrice*/

document.addEventListener('DOMContentLoaded', function () {
  const productVariable = document.querySelector('.product-type-variable')
  if (!productVariable) {
    return
  }
  var priceElement = document.querySelector('.woocommerce-Price-amount')

  function updateInitialPrice() {
    priceElement = document.querySelector('.woocommerce-Price-amount')
    if (priceElement) {
      priceElement.innerHTML =
        initialPrice.toLocaleString('sv-SE', {
          minimumFractionDigits: 0
        }) + ' kr'

      updatePrice()
    } else if (!priceElement) {
      // Retry after a short delay if the element is not yet available
      setTimeout(updateInitialPrice, 100)
    }
  }

  // Define updatePrice function globally
  window.updatePrice = function () {
    // Ensure priceElement is defined here
    if (!priceElement) {
      priceElement = document.querySelector('.woocommerce-Price-amount')
      if (!priceElement) {
        console.error('priceElement is not found.')
        return
      }
    }

    var utforande = document.querySelector('select[name="attribute_pa_utforande"]').value
    var breddValues = utforandeBreddValues[utforande] || {
      min: 0,
      max: 0
    }
    var breddInput = document.getElementById('tillagg_bredd')
    var errorMessage = document.getElementById('bredd-error-message')

    // Reset the input and error state
    breddInput.classList.remove('border-red-500')
    if (errorMessage) {
      errorMessage.remove()
    }

    // Update placeholder with min and max values
    breddInput.placeholder = 'Minst ' + breddValues.min + 'mm, Max ' + breddValues.max + 'mm'

    // Use min value if no bredd value is entered
    var bredd = parseInt(breddInput.value) || parseInt(breddValues.min)
    var hojd = parseInt(document.getElementById('tillagg_hojd').value) || 0

    breddInput.min = breddValues.min
    breddInput.max = breddValues.max

    // Validate bredd value and alert if out of bounds
    if (bredd < breddValues.min || bredd > breddValues.max) {
      // Add red border to the input
      breddInput.classList.add('border-red-500')

      // Create or update the error message
      if (!errorMessage) {
        errorMessage = document.createElement('div')
        errorMessage.id = 'bredd-error-message'
        errorMessage.className = 'mt-1 text-xs text-red-500'
        breddInput.parentNode.appendChild(errorMessage)
      }
      errorMessage.textContent =
        'Bredden måste vara mellan ' + breddValues.min + 'mm och ' + breddValues.max + 'mm.'

      // Reset to min value if out of bounds
      breddInput.value = breddValues.min

      // Don't update the price if the input is invalid
      return
    }

    var variationId = document.querySelector('input[name="variation_id"]').value
    var variationPrice = parseInt(variationPrices[variationId]) || 0

    // Calculate total price
    var totalPrice = variationPrice + heightPrice * hojd + breddPrice * bredd

    // Update the displayed price
    if (priceElement) {
      priceElement.innerHTML =
        totalPrice.toLocaleString('sv-SE', {
          minimumFractionDigits: 0
        }) + ' kr'
    }
  }
  updateInitialPrice()
})
