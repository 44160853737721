document.addEventListener('DOMContentLoaded', function () {
  const offerForm = document.getElementById('offer-form')
  if (!offerForm) {
    return
  }

  offerForm.addEventListener('submit', function (e) {
    e.preventDefault()
    const ajaxUrl = `${window.location.origin}/wp/wp-admin/admin-ajax.php`
    let formData = new FormData(this)
    const submitButton = this.querySelector('button[type="submit"]')
    const spinner = submitButton.querySelector('.spinner')
    const buttonText = submitButton.querySelector('span')
    const formMessage = document.getElementById('form-message')

    // Show spinner and change button text
    spinner.classList.remove('hidden')
    buttonText.textContent = 'Skickar...'
    submitButton.disabled = true

    fetch(ajaxUrl, {
      method: 'POST',
      body: formData
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data) // Debugging
        if (data.success) {
          formMessage.textContent = 'Form submitted successfully!'
          formMessage.classList.remove('hidden', 'text-red-500')
          formMessage.classList.add('text-green-500')
          buttonText.textContent = 'Skickat!'
        } else {
          formMessage.textContent = 'There was an error submitting the form: ' + data.data
          formMessage.classList.remove('hidden', 'text-green-500')
          formMessage.classList.add('text-red-500')
          buttonText.textContent = 'Skicka'
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        formMessage.textContent = 'There was an error submitting the form.'
        formMessage.classList.remove('hidden', 'text-green-500')
        formMessage.classList.add('text-red-500')
        buttonText.textContent = 'Skicka'
      })
      .finally(() => {
        // Hide spinner and reset button text
        spinner.classList.add('hidden')
        submitButton.disabled = false
      })
  })
})
